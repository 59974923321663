<template>
  <div style='overflow:visible;'>
    <div ref='quillToolbar'>

      <span class='ql-formats'>
        <select class='ql-font'>
          <option selected></option> <!-- Default "Sans Serif" Font -->
          <option value="courier">Courier</option>
          <option value="opensans">Open Sans</option>
          <option value="audiowide">Audiowide</option>
        </select>

        <select class="ql-size">
          <option value="small"></option>
          <option selected></option> <!-- Normal Size -->
          <option value="large"></option>
          <option value="huge"></option>
        </select>
      </span>

      <span class='ql-formats'>
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
      </span>

      <span class='ql-formats'>
        <select class="ql-color"></select>
        <select class="ql-background"></select>
      </span>

      <!--<span class='ql-formats'><button class="ql-image"></button></span>-->


    </div>
    <div ref='quillContainer' style='font-size:1.2em;' />
  </div>
</template>

<style src="quill/dist/quill.snow.css"></style>

<style lang="scss">
//import google fonts
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
//create quill font classes
.ql-font-courier { font-family: 'Courier'; }
.ql-font-opensans { font-family: 'Open Sans'; }
.ql-font-audiowide { font-family: 'Audiowide', cursive; }

</style>

<script>

import Quill from 'quill'

const allowed_formatting = ['background','bold','color','font','code','italic','link','size','strike','script',
  'underline','blockquote','header','indent','list','align','direction','code-block','formula'
  // 'image', 'video'
];

export default {
  name: 'Quill',

  components: {
  },

  data: () => ({
    container: null,
    toolbar: null,
    quill: null,
  }),

  computed: {
  },


  methods:{
    textChanged(delta, oldDelta, source) {
      if (source == 'api') {
        // api updated the textbox - no event
      } else if (source == 'user') {
        this.$emit('changed');
      }
    },

    getText(){
      let reader = new FileReader();
      reader.readA
      return JSON.stringify(this.quill.getContents());
    },

    setText(jsonString){
      try{
        this.quill.setContents(JSON.parse(jsonString || []));
      }
      catch(e){ /* JSON ERROR */ }
    },

  },

  mounted() {
    //get container references
    this.container = this.$refs['quillContainer'];
    this.toolbar = this.$refs['quillToolbar'];
    //register fonts
    var FontAttributor = Quill.import('attributors/class/font');
    FontAttributor.whitelist = ['courier', 'opensans', 'audiowide'];
    Quill.register(FontAttributor, true);
    // Create a quill instance on the container
    this.quill = new Quill(this.container, {
        modules: { toolbar: this.toolbar },
        theme: 'snow',
        formats: allowed_formatting,
    });
    // bind user text change event
    this.quill.on('text-change', this.textChanged);

  },

};
</script>
