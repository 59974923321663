<template>
  <div>
    <v-sheet rounded elevation="2" class='white rounded'>
      <v-sheet class='primary pa-3 rounded-t'>
        <v-row>
          <v-col class='text-h6 white--text'>Jobs</v-col>
          <v-col class='text-right white--text mt-1 mr-2'>
          </v-col>
        </v-row>
      </v-sheet>
      
      <v-row class="pa-4">
        <v-col cols="12" md="6" lg="4" >
          <v-text-field :value='search' @change="v=>{ search = v; }" dense outlined hide-details placeholder='find...' />
        </v-col>
      </v-row>
      

      <v-data-table
        :loading="loading"
        :headers='headers'
        :items='jobs'
        @click:row='clickJobRow'
        dense
        :server-items-length="jobs_total"
        :options.sync="options"
        :footer-props="{ 'items-per-page-options':[10,20,30,40,50], 'show-first-last-page': true, 'show-current-page': true }"
      >

        <template v-slot:item.job_type="{item}">
          <span v-if='item.job_type'>{{ item.job_type.name }}</span>
          <span v-else><i class='grey--text'>-</i></span>
        </template>

        <template v-slot:item.date_due="{item}">
          <Moment v-if='item.date_due' :timestamp='item.date_due' xmode='calendar' />
          <span v-else><i class='grey--text'>-</i></span>
        </template>
    
      </v-data-table>

    </v-sheet>

  </div>
</template>


<script>
import { mapActions } from 'vuex'
import Moment from '@/components/Moment'

export default {
  name: 'Notes',

  components: {
    Moment
  },

  props: {
    customerId: { type: String, required: true },
  },

  data: () => ({
    pageReady: false,
    loading: false,
    jobs: [],
    headers: [
      //{ text: 'ID', value: 'id' },
      { text: 'Job#', value: 'ref', sortable: false },
      { text: 'Type', value: 'job_type', sortable: false },
      { text: 'Job Name', value: 'description',sortable: false },
      { text: 'Status', value: 'status.name', sortable: false, },
      { text: 'Due', value: 'date_due', sortable: true, align: 'center' },
    ],
    jobs_total: 0,
    search: "",
    filters: { },
    options: {
      page: 1, sortBy: [], sortDesc: [], itemsPerPage: 30
    },

    clickJobRow(job){
      window.open(`/#/job/${job.xid}/`, "_blank");
    },

  }),

  computed: {

  },

  watch:{
    'options.page'(){ if(this.pageReady) this.loadJobs(); },
    'options.itemsPerPage'(){ if(this.pageReady) this.loadJobs(); },
    'options.sortBy'(){ if(this.pageReady) this.loadJobs(); },
    'options.sortDesc'(){ if(this.pageReady) this.loadJobs(); },
    'search'(){ if(this.pageReady) this.loadJobs(); },
  },

  methods:{
    ...mapActions('API', ['API']),

    loadJobs(){
      console.log("LJ");
      this.loading = true;
      let data = {
        ...this.filters,
        customer: this.customerId,
        page: this.options.page,
        items_per_page: this.options.itemsPerPage,
        search: this.search,
        sort: this.options.sortBy[0] ?? undefined, sortDesc: this.options.sortDesc[0] ?? undefined,
      };
      return this.API({ method: 'POST', endpoint: 'jobs/search', data })
      .then(paginate => {
        this.jobs = paginate.data;
        this.jobs_total = paginate.total;
      })
      .finally(() => { this.loading = false; });
    },

  },

  mounted() {
    this.loadJobs()
    .then(() => { this.pageReady = true; });
  },

};
</script>



<style lang="scss" scoped>
@import "@/assets/common";

#page{
  .v-data-table::v-deep{
    position: relative;
    tr{
      cursor: pointer;
    }
  }
}

</style>