<template>
  <v-container v-if='LOGGED_IN' id='page' class='mb-10'><v-row justify='center'><v-col cols=12 xl=10>

    <!-- TOP -->
    <Education>
      <ul>
        <li>Staff members can access and track jobs they are tagged on.</li>
        <li>Customer Locations should be added which will be billed and shipped to.</li>
        <li>Administrative staff can additionally access, track and pay for all jobs booked at their assigned locations.</li>
      </ul>
    </Education>

    <v-row>

      <!-- LEFT COLUMN -->
      <v-col cols=12 lg=7>
        <Notes :customerId='customerId' class='mb-5' />
        <Jobs :customerId='customerId' class='mb-5' />
      </v-col>

      <!-- RIGHT COLUMN -->
      <v-col cols=12 lg=5>
        <Locations :customerId='customerId' ref='locations' class='mb-5' @update='locationsUpdated' />
        <Staff :customerId='customerId' @staffChanged='StaffUpdated' />
        <Billing :customerId='customerId' ref='billing' />
      </v-col>

    </v-row>

  </v-col></v-row></v-container>
</template>

<style lang="scss" scoped>
@import "@/assets/common";

</style>


<script>
import { mapActions, mapGetters } from 'vuex'

import Education from '@/components/Education'
import Staff from '@/components/Customer/Staff'
import Locations from '@/components/Customer/Locations'
import Notes from '@/components/Customer/Notes'
import Billing from '@/components/Customer/Billing'
import Jobs from '@/components/Customer/Jobs'

export default {
  name: 'Customers',

  components: {
    Education, Staff, Locations, Notes, Billing, Jobs
  },

  data: () => ({
    customer: "",
  }),

  computed: {
    ...mapGetters('Auth', ['LOGGED_IN', 'TOKEN', 'MODULES']),

    customerId(){
      return this.$route.params.customerId;
    },

  },

  watch: {
    customerId(){ this.$emit('reload') },
  },

  methods:{
    ...mapActions('UI', ['SHOW_NAVBARS', 'NOTIFY', 'SET_PAGE_TITLE', 'PROMPT', 'NAVIGATE']),
    ...mapActions('Auth', ['REQUIRE_LOGIN']),
    ...mapActions('API', ['API']),

    loadCustomer(){
      return this.API({ method: 'GET', endpoint: `customers/${this.customerId}` })
      .then(customer => {
        this.customer = customer;
        this.SET_PAGE_TITLE(customer.name);
      });
    },

    locationsUpdated(){
      this.$refs['billing'].refreshLocations();
    },

    StaffUpdated(){
      this.$refs["locations"].loadStaff();
      this.$refs['billing'].refreshStaff();
    },

  },

  mounted() {
    this.REQUIRE_LOGIN(()=>{
      this.SHOW_NAVBARS();
      this.SET_PAGE_TITLE("Customer");
      this.loadCustomer();
    });
  },

};
</script>
