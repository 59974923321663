<template>
  <div>
    <v-sheet rounded elevation="2" class='white rounded' style='overflow:hidden;'>
      <v-sheet class='primary pa-3'>
        <v-row>
          <v-col class='text-h6 white--text'>Staff</v-col>
          <v-col class='text-right pa-2'>
            <v-btn class="white--text" fab small text @click='clickNewStaff'><v-icon>mdi-plus</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-sheet>
      <v-list dense>
        <v-list-item v-for="s in staff" v-bind:key="s.xid">
          <v-list-item-content>
            <v-list-item-title>
              {{s.user.name_first}} {{s.user.name_last}}
              <span class="font-weight-regular"> - {{s.user.username}}</span>
            </v-list-item-title>
            <v-list-item-subtitle v-if="s.user.job_description">{{s.user.job_description}}</v-list-item-subtitle>
          </v-list-item-content>
            <v-list-item-action class="py-0 my-0">
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon color="primary">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item link @click='editStaff_open(s.xid)'><v-icon class='mr-3 primary--text'>mdi-pencil</v-icon>Update Details</v-list-item>
                    <v-list-item link @click='clickPasswordReset(s)'><v-icon class='mr-3 primary--text'>mdi-lock-reset</v-icon>Reset Password</v-list-item>
                    <v-divider />
                    <v-list-item link @click="deleteStaff(s)"><v-icon class='mr-3 red--text'>mdi-trash-can</v-icon>Delete</v-list-item>
                  </v-list>
                </v-menu>
            </v-list-item-action>
          </v-list-item>
      </v-list>
    </v-sheet>

    <!-- EDIT STAFF DIALOG -->
    <v-dialog v-model="editStaff.show" v-if='editStaff.show' max-width="600" persistent scrollable>
      <v-card elevation="20" :disabled='editStaff.loading'>
        <v-card-title class="text-h6 primary white--text py-3">
          Update Staff
        </v-card-title>
        <v-card-text class='pt-8 text-subtitle-2'>
          <v-row style='py-0'>
            <v-col cols=12 md="6" style='py-0'><v-text-field v-model='editStaff.username' outlined dense hide-details label='LaserCAM Cloud Username'/></v-col>
          </v-row><v-row style='py-0'>
            <v-col cols=12 md=6 style='py-0'><v-text-field v-model='editStaff.name_first' outlined dense hide-details label='First Name'/></v-col>
            <v-col cols=12 md=6 style='py-0'><v-text-field v-model='editStaff.name_last' outlined dense hide-details label='Last Name'/></v-col>
            <v-col cols=12 style='py-0'><v-text-field v-model='editStaff.job_description' outlined dense hide-details label='Job Description'/></v-col>
            <v-col cols=12 md="4" style='py-0'><v-text-field v-model='editStaff.phone' outlined dense hide-details label='Phone'/></v-col>
            <v-col cols=12 md=8 style='py-0'><v-text-field v-model='editStaff.email' outlined dense hide-details label='Email'/></v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" @click='editStaff.show = false;'>Cancel</v-btn>
          <v-btn color="primary darken-1" @click='editStaff_save'>Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/common";



#page{
  .v-data-table::v-deep{
    position: relative;
    thead{
      background-color: $primary;
      th{
        color: white;
      }
    }
    tr{
      cursor: pointer;
    }
  }
}

</style>


<script>
import { mapActions } from 'vuex'

//import Education from '@/components/Education';

export default {
  name: 'Staff',

  components: {
    //Education,
  },

  props: {
    customerId: { type: String, required: true },
  },

  data: () => ({
    staff: [],
    filter: "",
    editStaff: {
      show: false,
      loading: false,
    },
  }),

  computed: {

    staffHeaders(){
      return [
        //{ text: 'ID', value: 'id' },
        { text: 'Username', value: 'user.username' },
        { text: 'First Name', value: 'user.user.name_first' },
        { text: 'Last Name', value: 'user.user.name_last' },
        { text: 'Email', value: 'user.user.email' },
      ];
    },

  },

  methods:{
    ...mapActions('UI', ['NOTIFY', 'PROMPT', 'CONFIRM', 'ALERT']),
    ...mapActions('API', ['API']),

    loadStaff(){
      return this.API({ method: 'GET', endpoint: `customers/${this.customerId}/staff` })
      .then(staff => {
        this.staff = staff;
      });
    },

    async clickNewStaff(){
      await this.CONFIRM({ title: 'New Staff', message: "Add new customer staff?" });
      return this.API({ method: 'GET', endpoint: `customers/${this.customerId}/staff/create` })
      .then(newStaffId => {
        this.$emit('staffChanged');
        this.loadStaff()
        .then(()=>{
          this.editStaff_open(newStaffId);
        });
      });
    },

    editStaff_open(staffId){
      let s = this.staff.find(s => s.xid == staffId);
      this.editStaff.xid = s.xid;
      this.editStaff.name_first = s.user.name_first;
      this.editStaff.name_last = s.user.name_last;
      this.editStaff.username = s.user.username;
      this.editStaff.phone = s.user.phone;
      this.editStaff.email = s.user.email;
      this.editStaff.job_description = s.user.job_description;
      this.editStaff.show = true;
    },

    editStaff_save(){
      this.editStaff.loading = true;
      return this.API({ method: 'PATCH', endpoint: `customers/${this.customerId}/staff/${this.editStaff.xid}`, data: {
        username: this.editStaff.username,
        first_name: this.editStaff.name_first,
        last_name: this.editStaff.name_last,
        phone: this.editStaff.phone,
        email: this.editStaff.email,
        job_description: this.editStaff.job_description,
      }})
      .then(() => { this.$emit('staffChanged'); })
      .then(this.loadStaff)
      .then(()=>{ this.editStaff.show = false; })
      .finally(()=>{ this.editStaff.loading = false; });
    },

    async deleteStaff(staff){
      let name = [staff.user.name_first, staff.user.name_last].filter(v=>v).join(" ");
      await this.CONFIRM({ title: 'Delete Staff', message: `Delete "${name}" from staff?` });
      this.API({ method: 'DELETE', endpoint: `customers/${this.customerId}/staff/${staff.xid}` })
      .then(() => { this.$emit('staffChanged'); })
      .then(this.loadStaff);
    },

    async clickPasswordReset(staff){
      let name = [staff.user.name_first, staff.user.name_last].filter(v=>v).join(" ");
      await this.CONFIRM({ title: 'Password Reset', message: `Send ${name} a password reset via email?` });
      this.API({ method: 'POST', endpoint: `customers/${this.customerId}/staff/${staff.xid}/password_reset` })
      .then(() => {
        this.ALERT({ title: 'Password Reset', message: 'Password reset requested.' })
      })
    }

  },

  mounted() {
    this.loadStaff();
  },

};
</script>
