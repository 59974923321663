<template>
  <div>
        <v-sheet rounded elevation="2" class='white rounded' style='overflow:hidden;'>

          <v-sheet class='primary pa-3'>
            <v-row>
              <v-col class='text-h6 white--text'>Locations</v-col>
              <v-col class='text-right pa-2'>
                <v-btn class="white--text" fab small text @click='clickNewLocation'><v-icon>mdi-plus</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-sheet>
          <v-list>
            <v-list-item v-for='l in locations' v-bind:key="l.xid">
              <v-list-item-content>
                <v-list-item-title>{{l.company_name}}</v-list-item-title>
                <v-list-item-subtitle class='text-caption' style='white-space:pre-wrap;'>{{l.address}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action-text>
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-chip small class='primary' @click='adminStaff_open(l.xid)' v-on="on">{{l.staff.length}} Admin Staff</v-chip>
                  </template>
                  <div v-for="s in staff.filter(s => l.staff.map(s => s.xid).includes(s.xid))" v-bind:key="s.xid">{{s.user.name_first}} {{s.user.name_last}}</div>
                  <div v-if="l.staff.length == 0">No admin staff</div>
                </v-tooltip>


              </v-list-item-action-text>
                <v-list-item-action>
                  <v-btn icon @click='editLocation_open(l.xid)'><v-icon color="primary">mdi-pencil</v-icon></v-btn>
                </v-list-item-action>
              </v-list-item>
          </v-list>
      </v-sheet>

    <!-- EDIT LOCATION DIALOG -->
    <v-dialog v-model="editLocation.show" v-if='editLocation.show' max-width="800" persistent scrollable>
      <v-card elevation="20" :disabled='editLocation.loading'>
        <v-card-title class="text-h6 primary white--text py-3">
          Update Location
        </v-card-title>
        <v-card-text class='pt-8 text-subtitle-2'>
          <v-row style='py-0'>
            <v-col cols=12 style='py-0'><v-text-field v-model='editLocation.company_name' outlined dense hide-details label='Business Name'/></v-col>
            <v-col cols=12 style='py-0'><v-text-field v-model='editLocation.address_line_1' outlined dense hide-details label='Address Line 1'/></v-col>
            <v-col cols=12 style='py-0'><v-text-field v-model='editLocation.address_line_2' outlined dense hide-details label='Address Line 2'/></v-col>
            <v-col cols=12 style='py-0'><v-text-field v-model='editLocation.address_line_3' outlined dense hide-details label='Address Line 3'/></v-col>
            <v-col cols=12 md=6 lg=3 style='py-0'><v-text-field v-model='editLocation.city_locality' outlined dense hide-details label='Suburb / Town'/></v-col>
            <v-col cols=12 md=6 lg=3 style='py-0'><v-combobox v-model='editLocation.state_province' outlined dense hide-details label='State' :items="['SA', 'VIC', 'NSW', 'QLD', 'TAS', 'WA', 'NT', 'ACT']" /></v-col>
            <v-col cols=12 md=6 lg=3 style='py-0'><v-text-field v-model='editLocation.postal_code' outlined dense hide-details label='Postcode' maxLength=5 /></v-col>
            <v-col cols=12 md=6 lg=3 style='py-0'><v-select v-model='editLocation.country_code' outlined dense hide-details :items="countryCodes" label='Country' /></v-col>
            <v-col cols=12 md=3><v-checkbox class='my-0' outlined dense hide-details label='Residential' v-model='editLocation.residential' /></v-col>
            <v-col cols=12 md=9><v-btn class='primary float-right' small @click='verifyAddress'>Verify</v-btn></v-col>
            <v-col cols=12 class='d-flex align-center'><v-divider class='mr-2' />Contact Details<v-divider class='ml-2' /></v-col>
            <v-col cols=12 md=6><v-text-field outlined dense hide-details label='Phone' v-model='editLocation.phone' /></v-col>
            <v-col cols=12 md=6><v-text-field outlined dense hide-details label='Email' v-model='editLocation.email' /></v-col>
            <v-col cols=12 class='d-flex align-center'><v-divider class='mr-2' />Dispatch Settings<v-divider class='ml-2' /></v-col>
            <v-col cols=12 md=6>
              <v-textarea outlined label='Dispatch Notes' placeholder='For internal reference' hide-details :value='editLocation.dispatch_notes' @change='v=>{ editLocation.dispatch_notes = v; }' />
            </v-col>
            <v-col cols=12 md=6>
              <v-textarea outlined label='Courier Instructions' placeholder='Text to appear on shipping labels.' hide-details :value='editLocation.courier_notes' @change='v=>{ editLocation.courier_notes = v; }' />
            </v-col>
            <v-col rows=12 md=6>
              <LazyDropdown outlined dense v-model='editLocation.courier' label='Default Courier' url='dispatch/couriers' item-text='label' item-value='xid' />
            </v-col>
            <v-col rows=12 md=6>
              <v-select hide-details outlined dense label='Accepts Delivery' :value='editLocation.delivery' @change='v=>editLocation.delivery=v.sort()' :items="daysOfWeek" multiple />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn icon color="red" @click="deleteLocation"><v-icon>mdi-trash-can</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" @click='editLocation.show = false;'>Cancel</v-btn>
          <v-btn color="primary darken-1" @click='editLocation_save'>Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ADMIN STAFF DIALOG -->
    <v-dialog v-model="adminStaff.show" v-if='adminStaff.show' max-width="500" persistent>
      <v-card elevation="20" :disabled='adminStaff.loading'>
        <v-card-title class="text-h6 primary white--text py-3">
          Administrative Staff
        </v-card-title>
        <v-card-text class='pt-8 text-subtitle-2'>
          <v-row>
            <v-chip v-for='s in staff' v-bind:key='s.xid'
              label class='ma-1'
              @click='toggleAdmin(s.xid)'
              :class='{ primary: (locationAdmins.includes(s.xid)) }'
            >
              {{s.user.name_first}} {{s.user.name_last}}
            </v-chip>
          </v-row>
        </v-card-text>
        <v-card-actions class='pb-4'>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" @click='adminStaff.show = false;'>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/common";



#page{
  .v-data-table::v-deep{
    position: relative;
    thead{
      background-color: $primary;
      th{
        color: white;
      }
    }
    tr{
      cursor: pointer;
    }
  }
}

</style>


<script>
import { mapActions } from 'vuex'
import countryCodes from '@/helpers/CountryCodes'
import LazyDropdown from '@/components/LazyDropdown'
//import Education from '@/components/Education'

export default {
  name: 'Locations',

  components: {
    LazyDropdown
  },

  props: {
    customerId: { type: String, required: true },
  },

  data: () => ({
    staff: [],
    locations: [],
    filter: "",
    editLocation: {
      show: false,
      loading: false,
      delivery: [44],
      country_code: null,
    },
    adminStaff:{
      show: false,
      loading: false,
      locationId: null,
    },
    courier: null,
    daysOfWeek: [ { value: 0, text: "Mon" }, { value: 1, text: "Tues" }, { value: 2, text: "Wed" }, { value: 3, text: "Thur" }, { value: 4, text: "Fri" }, { value: 5, text: "Sat" }, { value: 6, text: "Sun" } ]
  }),

  computed: {

    countryCodes(){ return countryCodes; },

    locationAdmins(){
      let location = this.locations.find(l => l.xid == this.adminStaff.locationId);
      if(!location) return null;
      return location.staff.map(s => s.xid);
    },

  },

  methods:{
    ...mapActions('UI', ['NOTIFY', 'PROMPT', 'CONFIRM']),
    ...mapActions('API', ['API']),

    loadStaff(){
      return this.API({ method: 'GET', endpoint: `customers/${this.customerId}/staff` })
      .then(staff => {
        this.staff = staff;
      });
    },

    loadLocations(){
      return this.API({ method: 'GET', endpoint: `customers/${this.customerId}/locations` })
      .then(locations => {
        this.locations = locations;
      });
    },

    clickLocationStaff(location){
      this.locationStaffForm.location = location;
      this.locationStaffForm.show = true;
    },

    async clickNewLocation(){
      await this.CONFIRM({ title: 'New Location', message: "Add new customer location?" });
      return this.API({ method: 'GET', endpoint: `customers/${this.customerId}/locations/create` })
      .then(newLocationId => {
        this.$emit('update');
        this.loadLocations()
        .then(()=>{ this.editLocation_open(newLocationId); });
      });
    },

    editLocation_open(locationId){
      let data = this.locations.find(l => l.xid == locationId);
      this.editLocation = {
        ...this.editLocation,
        ...data,
        show: true
      };
    },

    editLocation_save(){
      this.editLocation.loading = true;
      return this.API({ method: 'PATCH', endpoint: `customers/${this.customerId}/locations/${this.editLocation.xid}`, data: {
        ...this.editLocation,
        courier: this.editLocation.courier?.xid ?? null,
      }})
      .then(this.loadLocations)
      .then(()=>{
        this.$emit('update');
        this.editLocation.show = false;
      })
      .finally(()=>{ this.editLocation.loading = false; });
    },

    adminStaff_open(locationId){
      this.adminStaff.locationId = locationId;
      this.adminStaff.show = true;
      this.adminStaff.loading = false;
    },

    toggleAdmin(staffId){
      let isAdmin = this.locationAdmins.includes(staffId);
      let req = {};
      if(isAdmin)
        req = { method: 'DELETE', endpoint: `customers/${this.customerId}/locations/${this.adminStaff.locationId}/staff/${staffId}` };
      else
        req = { method: 'PUT', endpoint: `customers/${this.customerId}/locations/${this.adminStaff.locationId}/staff`, data: { staff: staffId } };
      this.adminStaff.loading = true;
      this.API(req)
      .then(this.loadLocations)
      .finally(() => {
        this.adminStaff.loading = false;
      });
    },

    verifyAddress(){
      console.log("VERIFY ADDRESS");
    },

    async deleteLocation(){
      await this.CONFIRM({ title: "Delete Location", message: "Are you sure you want to delete this business location?" })
      this.editLocation.loading = true;
      this.API({ method: 'DELETE', endpoint: `customers/${this.customerId}/locations/${this.editLocation.xid}` })
      .then(this.loadLocations)
      .then(()=>{
        this.$emit('update');
        this.editLocation.show = false;
      })
      .finally(()=>{ this.editLocation.loading = false; });
    }

  },

  mounted() {
      this.loadStaff();
      this.loadLocations();
  },

};
</script>
