var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "overflow": "visible"
    }
  }, [_c('div', {
    ref: "quillToolbar"
  }, [_vm._m(0), _vm._m(1), _vm._m(2)]), _c('div', {
    ref: "quillContainer",
    staticStyle: {
      "font-size": "1.2em"
    }
  })]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "ql-formats"
  }, [_c('select', {
    staticClass: "ql-font"
  }, [_c('option', {
    attrs: {
      "selected": ""
    }
  }), _vm._v(" "), _c('option', {
    attrs: {
      "value": "courier"
    }
  }, [_vm._v("Courier")]), _c('option', {
    attrs: {
      "value": "opensans"
    }
  }, [_vm._v("Open Sans")]), _c('option', {
    attrs: {
      "value": "audiowide"
    }
  }, [_vm._v("Audiowide")])]), _c('select', {
    staticClass: "ql-size"
  }, [_c('option', {
    attrs: {
      "value": "small"
    }
  }), _c('option', {
    attrs: {
      "selected": ""
    }
  }), _vm._v(" "), _c('option', {
    attrs: {
      "value": "large"
    }
  }), _c('option', {
    attrs: {
      "value": "huge"
    }
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "ql-formats"
  }, [_c('button', {
    staticClass: "ql-bold"
  }), _c('button', {
    staticClass: "ql-italic"
  }), _c('button', {
    staticClass: "ql-underline"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "ql-formats"
  }, [_c('select', {
    staticClass: "ql-color"
  }), _c('select', {
    staticClass: "ql-background"
  })]);
}]

export { render, staticRenderFns }