var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-sheet', {
    staticClass: "white rounded",
    attrs: {
      "rounded": "",
      "elevation": "2"
    }
  }, [_c('v-sheet', {
    staticClass: "primary pa-3 rounded-t"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-h6 white--text"
  }, [_vm._v("Customer Notes")]), _c('v-col', {
    staticClass: "text-right white--text mt-1 mr-2"
  }, [_vm._v(" " + _vm._s(_vm.save_status) + " ")])], 1)], 1), _c('Quill', {
    ref: "quill",
    on: {
      "changed": _vm.changed
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }