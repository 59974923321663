<template>
  <div>
    <v-sheet rounded elevation="2" class='white rounded'>
      <v-sheet class='primary pa-3 rounded-t'>
        <v-row>
          <v-col class='text-h6 white--text'>Customer Notes</v-col>
          <v-col class='text-right white--text mt-1 mr-2'>
            <!--<v-btn rounded class="white--text" outlined @click='clickNewStaff'><v-icon>mdi-plus</v-icon>New Staff</v-btn>-->
            {{save_status}}
          </v-col>
        </v-row>
      </v-sheet>
      <Quill ref='quill' @changed='changed'/>
    </v-sheet>

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/common";



#page{
  .v-data-table::v-deep{
    position: relative;
    thead{
      background-color: $primary;
      th{
        color: white;
      }
    }
    tr{
      cursor: pointer;
    }
  }
}

</style>


<script>
import { mapActions } from 'vuex'

import Quill from '@/components/Quill';

export default {
  name: 'Notes',

  components: {
    Quill,
  },

  props: {
    customerId: { type: String, required: true },
  },

  data: () => ({
    quill: null,
    save_status: "",
  }),

  computed: {

    staffHeaders(){
      return [
        //{ text: 'ID', value: 'id' },
        { text: 'Username', value: 'user.username' },
        { text: 'First Name', value: 'user.user.name_first' },
        { text: 'Last Name', value: 'user.user.name_last' },
        { text: 'Email', value: 'user.user.email' },
      ];
    },

  },

  methods:{
    ...mapActions('UI', ['NOTIFY', 'PROMPT']),
    ...mapActions('API', ['API']),

    loadNotes(){
      this.API({ method: 'GET', endpoint: `customers/${this.customerId}/notes` })
      .then(this.quill.setText);
    },

    changed(){
      this.save_status = "Autosaving...";
      this.API({ method: 'POST', endpoint: `customers/${this.customerId}/notes`, data: {notes: this.quill.getText()}, autosave: 1500 })
      .then(() => {
        this.save_status = "Saved.";
        this.NOTIFY({message: "💾 Notes saved."});
      })
      .catch(() => {
        this.save_status = "Save failed."
        this.NOTIFY({message: "⚠ Notes failed to save."});
      })
    },

  },

  mounted() {
    this.quill = this.$refs['quill'];
    this.loadNotes();
  },

};
</script>
