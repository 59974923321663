var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-sheet', {
    staticClass: "white rounded",
    attrs: {
      "rounded": "",
      "elevation": "2"
    }
  }, [_c('v-sheet', {
    staticClass: "primary pa-3 rounded-t"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-h6 white--text"
  }, [_vm._v("Jobs")]), _c('v-col', {
    staticClass: "text-right white--text mt-1 mr-2"
  })], 1)], 1), _c('v-row', {
    staticClass: "pa-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.search,
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "find..."
    },
    on: {
      "change": function change(v) {
        _vm.search = v;
      }
    }
  })], 1)], 1), _c('v-data-table', {
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.headers,
      "items": _vm.jobs,
      "dense": "",
      "server-items-length": _vm.jobs_total,
      "options": _vm.options,
      "footer-props": {
        'items-per-page-options': [10, 20, 30, 40, 50],
        'show-first-last-page': true,
        'show-current-page': true
      }
    },
    on: {
      "click:row": _vm.clickJobRow,
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.job_type",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.job_type ? _c('span', [_vm._v(_vm._s(item.job_type.name))]) : _c('span', [_c('i', {
          staticClass: "grey--text"
        }, [_vm._v("-")])])];
      }
    }, {
      key: "item.date_due",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.date_due ? _c('Moment', {
          attrs: {
            "timestamp": item.date_due,
            "xmode": "calendar"
          }
        }) : _c('span', [_c('i', {
          staticClass: "grey--text"
        }, [_vm._v("-")])])];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }