var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "white rounded mt-5",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "rounded": "",
      "elevation": "2",
      "disabled": _vm.loading,
      "loading": _vm.loading ? 'white' : false
    }
  }, [_c('v-card-title', {
    staticClass: "primary pa-3"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-h6 white--text"
  }, [_vm._v("Billing")])], 1)], 1), _c('v-card-text', {
    staticClass: "pa-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('LazyDropdown', {
    ref: "address",
    attrs: {
      "outlined": "",
      "dense": "",
      "url": '/customers/' + _vm.customerId + '/locations',
      "label": "Invoice Address",
      "item-text": "company_name",
      "item-value": "xid"
    },
    on: {
      "input": _vm.updateAddress
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', [_c('div', [_vm._v(_vm._s(item.company_name))]), _c('div', {
          staticClass: "text-caption secondary--text"
        }, [_vm._v(_vm._s([item.address_line_1, item.address_line_2, item.address_line_3].filter(function (v) {
          return v;
        }).join(', ')))]), _c('div', {
          staticClass: "text-caption secondary--text"
        }, [_vm._v(_vm._s([item.city_locality, item.state_province, item.postal_code].filter(function (v) {
          return v;
        }).join(' ')))])])];
      }
    }]),
    model: {
      value: _vm.invoice_address,
      callback: function callback($$v) {
        _vm.invoice_address = $$v;
      },
      expression: "invoice_address"
    }
  }), _c('p', {
    staticClass: "mt-2 mb-0 text-caption font-italic secondary--text"
  }, [_vm._v("If unset, invoices will be billed to each jobs delivery location.")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "ABN"
    },
    on: {
      "change": _vm.updateABN
    },
    model: {
      value: _vm.abn,
      callback: function callback($$v) {
        _vm.abn = $$v;
      },
      expression: "abn"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('LazyDropdown', {
    attrs: {
      "outlined": "",
      "dense": "",
      "url": "/taxes",
      "label": "Tax Applicable",
      "item-text": "title",
      "item-value": "xid"
    },
    on: {
      "input": _vm.updateTax
    },
    model: {
      value: _vm.tax,
      callback: function callback($$v) {
        _vm.tax = $$v;
      },
      expression: "tax"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "rows": "1",
      "auto-grow": "",
      "label": "Invoice Comments"
    },
    on: {
      "change": _vm.updateInvoiceComments
    },
    model: {
      value: _vm.invoice_comments,
      callback: function callback($$v) {
        _vm.invoice_comments = $$v;
      },
      expression: "invoice_comments"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Customer Discount",
      "suffix": "%"
    },
    on: {
      "change": _vm.updateDiscount
    },
    model: {
      value: _vm.discount,
      callback: function callback($$v) {
        _vm.discount = $$v;
      },
      expression: "discount"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-checkbox', {
    staticClass: "mt-1",
    attrs: {
      "hide-detials": "",
      "label": "Purchase Orders Required",
      "dense": ""
    },
    on: {
      "change": _vm.updatePurchaseOrdersReq
    },
    model: {
      value: _vm.purchase_orders_required,
      callback: function callback($$v) {
        _vm.purchase_orders_required = $$v;
      },
      expression: "purchase_orders_required"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('AutoBilling', {
    attrs: {
      "customer-id": _vm.customerId
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }