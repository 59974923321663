<template>
  <div>
    
    <v-list dense class="pt-0">
      <v-subheader>Base Charges<v-spacer /><v-btn icon small color="primary" @click="clickAddItem"><v-icon>mdi-plus</v-icon></v-btn></v-subheader>  
      
      <span ref="sortArea">

      <v-list-item class="px-1" @click="clickEdit(item)" v-for="item in items" :key="item.xid" :data-id="item.xid">

        <v-list-item-icon class="dragMe"><v-icon>mdi-drag</v-icon></v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{item.qty}}x <span v-if="item.product">{{ item.product.title }}</span></v-list-item-title>
          <template v-if="item.product">
            <v-list-item-subtitle>{{ item.product.description }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.product.tier">${{ (item.product.tier.price*item.qty/100).toFixed(2) }}</v-list-item-subtitle>
          </template>
          
        </v-list-item-content>
        <v-list-item-action class="my-0">
          <div>
            <v-chip x-small color="primary" class="mr-1" v-if="item.eva">EVA</v-chip>
            <v-chip x-small color="primary" class="mr-1" v-if="item.printed">3DP</v-chip>
            <v-chip x-small color="primary" class="mr-1" v-if="item.vac">VAC</v-chip>
            <v-btn small icon><v-icon color="red" @click.stop="clickDelete(item)">mdi-trash-can</v-icon></v-btn>
          </div>
        </v-list-item-action>
      </v-list-item>

      </span>

    </v-list>

    <v-dialog :value="dialog.open" persistent scrollable max-width="500px">
      <v-card :disabled="dialog.loading">
        <v-card-title class="primary white--text">Base Charge</v-card-title>
        <v-card-text class="pt-6">
          <v-row>
            <v-col cols="3">
              <v-text-field type="number" outlined dense hide-details label="Qty" v-model="dialog.qty" />
            </v-col>
            <v-col cols="9">
              <LazyDropdown 
                outlined dense label="Product"
                url="products" item-text="title" item-value="xid"
                v-model="dialog.product"
              >
                <template #selection="{item}"><TierIcon :tier="item.tier" size="24px" class="mr-2" :tooltip="true" /><span>{{ item.title }}</span></template>
                <template #item="{item}"><TierIcon :tier="item.tier" size="30px" class="mr-4" :tooltip="true" /><span>{{ item.title }}</span></template>
              </LazyDropdown>
            </v-col>
            <v-col cols="12" class="pa-1 text-center">
              <v-chip class='ma-1' label small @click="dialog.eva=!dialog.eva" :color="dialog.eva?'primary':'secondary'"><v-icon small class="mr-2">mdi-{{ dialog.eva?'check':'close' }}</v-icon>Machined EVA</v-chip>
              <v-chip class='ma-1' label small @click="dialog.printed=!dialog.printed" :color="dialog.printed?'primary':'secondary'"><v-icon small class="mr-2">mdi-{{ dialog.printed?'check':'close' }}</v-icon>3D Printed</v-chip>
              <v-chip class='ma-1' label small @click="dialog.vac=!dialog.vac" :color="dialog.vac?'primary':'secondary'"><v-icon small class="mr-2">mdi-{{ dialog.vac?'check':'close' }}</v-icon>Vacuum Formed</v-chip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="grey lighten-4">
          <v-spacer />
          <v-btn class="secondary" @click="dialog.open = false">Cancel</v-btn>
          <v-btn class="primary" v-if="dialog.xid" @click="clickUpdate" :loading="dialog.loading">Update</v-btn>
          <v-btn class="primary" v-else @click="clickSave" :loading="dialog.loading">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<style lang="scss" scoped> //@import "@/assets/common";
.dragMe{ cursor:move; }
</style>

<script>
import { mapActions } from 'vuex'
import Sortable from 'sortablejs';
import LazyDropdown from '../LazyDropdown.vue';
import TierIcon from '@/components/Billing/TierIcon';
export default {
  name: 'AutoBilling',
  components: { LazyDropdown, TierIcon },
  props: {
    customerId: { type: String, required: true },
  },

  data: () => ({
    loading: false,
    items: [],
    items_sortable: null,
    dialog: {
      open: false, loading: false, xid: null,
      product: null, qty: 1,
      eva: false, printed: false, vac: false,
    },
  }),

  computed: {
  },

  methods:{
    ...mapActions('API', ['API']),
    ...mapActions('UI', ['CONFIRM']),

    loadItems(){
      this.loading = true;
      this.items = [];
      return this.API({ method: 'GET', endpoint: `customers/${this.customerId}/autobilling` })
      .then(items => { this.items = items; })
      .finally(() => { this.loading = false; });
    },

    clickAddItem(){
      this.dialog = {
        ...this.dialog,
        open: true, loading: false, xid: null,
        product: null, qty: 1, eva: false, printed: false, vac: false,
      };
    },

    clickEdit(item){
      let { xid, product, qty, eva, printed, vac } = item;
      this.dialog = {
        ...this.dialog,
        open: true, loading: false, xid, product, qty, eva, printed, vac
      };
    },

    clickSave(){
      this.dialog.loading = true;
      let { qty, product, eva, printed, vac } = this.dialog;
      product = product?.xid ?? null;
      let data = { qty, eva, printed, vac, product };
      this.API({ method: 'POST', endpoint: `customers/${this.customerId}/autobilling`, data })
      .then(() => {
        this.dialog.open = false;
        this.loadItems();
      })
      .finally(() => { this.dialog.loading = false; });
    },

    clickUpdate(){
      this.dialog.loading = true;
      let { xid, qty, product, eva, printed, vac } = this.dialog;
      product = product?.xid ?? null;
      let data = { qty, eva, printed, vac, product };
      this.API({ method: 'PATCH', endpoint: `customers/${this.customerId}/autobilling/${xid}`, data })
      .then(item => {
        this.dialog.open = false;
        this.items[this.items.findIndex(item => item.xid == xid)] = item;
      })
      .finally(() => { this.dialog.loading = false; });
    },

    async clickDelete(item){
      let { xid } = item;
      await this.CONFIRM({ title: 'Delete Base Charge?', message: 'Are you sure you want to delete this charge?' })
      this.loading = true;
      this.API({ method: 'DELETE', endpoint: `customers/${this.customerId}/autobilling/${xid}` })
      .then(() => {
        this.items.splice(this.items.findIndex(item => item.xid == xid), 1);
      })
      .finally(() => { this.loading = false; });
    },

    updateSortOrder(e){
      if(e.oldIndex == e.newIndex) return;
      let sort_order = this.items_sortable.toArray();
      let data = { sort_order };
      console.log(data);
      this.API({ method: 'POST', endpoint: `customers/${this.customerId}/autobilling/sort`, data, /*autosave: 3000,*/ })
    },

    makeSortable(){
      this.items_sortable = new Sortable(this.$refs.sortArea, {
        animation: 250,
        handle: '.dragMe',
        swapThreshold: 1,
        onEnd: this.updateSortOrder,
      });
    },

  },

  mounted() {
    this.makeSortable();
    this.loadItems();
  },

}
</script>
