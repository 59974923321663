var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-sheet', {
    staticClass: "white rounded",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "rounded": "",
      "elevation": "2"
    }
  }, [_c('v-sheet', {
    staticClass: "primary pa-3"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-h6 white--text"
  }, [_vm._v("Locations")]), _c('v-col', {
    staticClass: "text-right pa-2"
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "fab": "",
      "small": "",
      "text": ""
    },
    on: {
      "click": _vm.clickNewLocation
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)], 1)], 1), _c('v-list', _vm._l(_vm.locations, function (l) {
    return _c('v-list-item', {
      key: l.xid
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(l.company_name))]), _c('v-list-item-subtitle', {
      staticClass: "text-caption",
      staticStyle: {
        "white-space": "pre-wrap"
      }
    }, [_vm._v(_vm._s(l.address))])], 1), _c('v-list-item-action-text', [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('v-chip', _vm._g({
            staticClass: "primary",
            attrs: {
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.adminStaff_open(l.xid);
              }
            }
          }, on), [_vm._v(_vm._s(l.staff.length) + " Admin Staff")])];
        }
      }], null, true)
    }, [_vm._l(_vm.staff.filter(function (s) {
      return l.staff.map(function (s) {
        return s.xid;
      }).includes(s.xid);
    }), function (s) {
      return _c('div', {
        key: s.xid
      }, [_vm._v(_vm._s(s.user.name_first) + " " + _vm._s(s.user.name_last))]);
    }), l.staff.length == 0 ? _c('div', [_vm._v("No admin staff")]) : _vm._e()], 2)], 1), _c('v-list-item-action', [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.editLocation_open(l.xid);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "primary"
      }
    }, [_vm._v("mdi-pencil")])], 1)], 1)], 1);
  }), 1)], 1), _vm.editLocation.show ? _c('v-dialog', {
    attrs: {
      "max-width": "800",
      "persistent": "",
      "scrollable": ""
    },
    model: {
      value: _vm.editLocation.show,
      callback: function callback($$v) {
        _vm.$set(_vm.editLocation, "show", $$v);
      },
      expression: "editLocation.show"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "20",
      "disabled": _vm.editLocation.loading
    }
  }, [_c('v-card-title', {
    staticClass: "text-h6 primary white--text py-3"
  }, [_vm._v(" Update Location ")]), _c('v-card-text', {
    staticClass: "pt-8 text-subtitle-2"
  }, [_c('v-row', {
    staticStyle: {}
  }, [_c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Business Name"
    },
    model: {
      value: _vm.editLocation.company_name,
      callback: function callback($$v) {
        _vm.$set(_vm.editLocation, "company_name", $$v);
      },
      expression: "editLocation.company_name"
    }
  })], 1), _c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Address Line 1"
    },
    model: {
      value: _vm.editLocation.address_line_1,
      callback: function callback($$v) {
        _vm.$set(_vm.editLocation, "address_line_1", $$v);
      },
      expression: "editLocation.address_line_1"
    }
  })], 1), _c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Address Line 2"
    },
    model: {
      value: _vm.editLocation.address_line_2,
      callback: function callback($$v) {
        _vm.$set(_vm.editLocation, "address_line_2", $$v);
      },
      expression: "editLocation.address_line_2"
    }
  })], 1), _c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Address Line 3"
    },
    model: {
      value: _vm.editLocation.address_line_3,
      callback: function callback($$v) {
        _vm.$set(_vm.editLocation, "address_line_3", $$v);
      },
      expression: "editLocation.address_line_3"
    }
  })], 1), _c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Suburb / Town"
    },
    model: {
      value: _vm.editLocation.city_locality,
      callback: function callback($$v) {
        _vm.$set(_vm.editLocation, "city_locality", $$v);
      },
      expression: "editLocation.city_locality"
    }
  })], 1), _c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('v-combobox', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "State",
      "items": ['SA', 'VIC', 'NSW', 'QLD', 'TAS', 'WA', 'NT', 'ACT']
    },
    model: {
      value: _vm.editLocation.state_province,
      callback: function callback($$v) {
        _vm.$set(_vm.editLocation, "state_province", $$v);
      },
      expression: "editLocation.state_province"
    }
  })], 1), _c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Postcode",
      "maxLength": "5"
    },
    model: {
      value: _vm.editLocation.postal_code,
      callback: function callback($$v) {
        _vm.$set(_vm.editLocation, "postal_code", $$v);
      },
      expression: "editLocation.postal_code"
    }
  })], 1), _c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "items": _vm.countryCodes,
      "label": "Country"
    },
    model: {
      value: _vm.editLocation.country_code,
      callback: function callback($$v) {
        _vm.$set(_vm.editLocation, "country_code", $$v);
      },
      expression: "editLocation.country_code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-checkbox', {
    staticClass: "my-0",
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Residential"
    },
    model: {
      value: _vm.editLocation.residential,
      callback: function callback($$v) {
        _vm.$set(_vm.editLocation, "residential", $$v);
      },
      expression: "editLocation.residential"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-btn', {
    staticClass: "primary float-right",
    attrs: {
      "small": ""
    },
    on: {
      "click": _vm.verifyAddress
    }
  }, [_vm._v("Verify")])], 1), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider', {
    staticClass: "mr-2"
  }), _vm._v("Contact Details"), _c('v-divider', {
    staticClass: "ml-2"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Phone"
    },
    model: {
      value: _vm.editLocation.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editLocation, "phone", $$v);
      },
      expression: "editLocation.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Email"
    },
    model: {
      value: _vm.editLocation.email,
      callback: function callback($$v) {
        _vm.$set(_vm.editLocation, "email", $$v);
      },
      expression: "editLocation.email"
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider', {
    staticClass: "mr-2"
  }), _vm._v("Dispatch Settings"), _c('v-divider', {
    staticClass: "ml-2"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "label": "Dispatch Notes",
      "placeholder": "For internal reference",
      "hide-details": "",
      "value": _vm.editLocation.dispatch_notes
    },
    on: {
      "change": function change(v) {
        _vm.editLocation.dispatch_notes = v;
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "label": "Courier Instructions",
      "placeholder": "Text to appear on shipping labels.",
      "hide-details": "",
      "value": _vm.editLocation.courier_notes
    },
    on: {
      "change": function change(v) {
        _vm.editLocation.courier_notes = v;
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "rows": "12",
      "md": "6"
    }
  }, [_c('LazyDropdown', {
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "Default Courier",
      "url": "dispatch/couriers",
      "item-text": "label",
      "item-value": "xid"
    },
    model: {
      value: _vm.editLocation.courier,
      callback: function callback($$v) {
        _vm.$set(_vm.editLocation, "courier", $$v);
      },
      expression: "editLocation.courier"
    }
  })], 1), _c('v-col', {
    attrs: {
      "rows": "12",
      "md": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "hide-details": "",
      "outlined": "",
      "dense": "",
      "label": "Accepts Delivery",
      "value": _vm.editLocation.delivery,
      "items": _vm.daysOfWeek,
      "multiple": ""
    },
    on: {
      "change": function change(v) {
        return _vm.editLocation.delivery = v.sort();
      }
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "icon": "",
      "color": "red"
    },
    on: {
      "click": _vm.deleteLocation
    }
  }, [_c('v-icon', [_vm._v("mdi-trash-can")])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary darken-1"
    },
    on: {
      "click": function click($event) {
        _vm.editLocation.show = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    attrs: {
      "color": "primary darken-1"
    },
    on: {
      "click": _vm.editLocation_save
    }
  }, [_vm._v("Update")])], 1)], 1)], 1) : _vm._e(), _vm.adminStaff.show ? _c('v-dialog', {
    attrs: {
      "max-width": "500",
      "persistent": ""
    },
    model: {
      value: _vm.adminStaff.show,
      callback: function callback($$v) {
        _vm.$set(_vm.adminStaff, "show", $$v);
      },
      expression: "adminStaff.show"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "20",
      "disabled": _vm.adminStaff.loading
    }
  }, [_c('v-card-title', {
    staticClass: "text-h6 primary white--text py-3"
  }, [_vm._v(" Administrative Staff ")]), _c('v-card-text', {
    staticClass: "pt-8 text-subtitle-2"
  }, [_c('v-row', _vm._l(_vm.staff, function (s) {
    return _c('v-chip', {
      key: s.xid,
      staticClass: "ma-1",
      class: {
        primary: _vm.locationAdmins.includes(s.xid)
      },
      attrs: {
        "label": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toggleAdmin(s.xid);
        }
      }
    }, [_vm._v(" " + _vm._s(s.user.name_first) + " " + _vm._s(s.user.name_last) + " ")]);
  }), 1)], 1), _c('v-card-actions', {
    staticClass: "pb-4"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary darken-1"
    },
    on: {
      "click": function click($event) {
        _vm.adminStaff.show = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }