<template>
  <div>
    <v-card rounded elevation="2" class='white rounded mt-5' style='overflow:hidden;' :disabled='loading' :loading="loading?'white':false">

      <v-card-title class='primary pa-3'>
        <v-row>
          <v-col class='text-h6 white--text'>Billing</v-col>
        </v-row>
      </v-card-title>
      <v-card-text class='pa-4'>
        <v-row>

          <v-col cols=12 class=''>
            <LazyDropdown outlined dense ref='address' :url="'/customers/'+customerId+'/locations'" v-model="invoice_address" label="Invoice Address" item-text="company_name" item-value="xid" @input='updateAddress'>
              <template #item="{item}">
                <div>
                  <div>{{ item.company_name }}</div>
                  <div class="text-caption secondary--text">{{ [item.address_line_1, item.address_line_2, item.address_line_3].filter(v=>v).join(', ') }}</div>
                  <div class="text-caption secondary--text">{{ [item.city_locality, item.state_province, item.postal_code].filter(v=>v).join('  ') }}</div>
                </div>
              </template>
            </LazyDropdown>
            <p class='mt-2 mb-0 text-caption font-italic secondary--text'>If unset, invoices will be billed to each jobs delivery location.</p>
          </v-col>

          <v-col cols=12 md=6 class=''>
            <v-text-field outlined dense hide-details label='ABN' v-model='abn' @change='updateABN' />
          </v-col>

          <v-col cols=6 class=''>
            <LazyDropdown outlined dense url="/taxes" v-model="tax" label="Tax Applicable" item-text="title" item-value="xid" @input='updateTax' />
          </v-col>

          <v-col cols=12 class=''>
            <v-textarea outlined dense hide-details rows=1 auto-grow label="Invoice Comments" v-model="invoice_comments" @change="updateInvoiceComments"  />
          </v-col>

          <v-col cols=12 md=6 class=''>
            <v-text-field
             outlined dense hide-details label='Customer Discount'
             suffix='%'
             v-model='discount'
             @change='updateDiscount'
            />
          </v-col>

          <v-col cols=12 md=6 class=''>
            <v-checkbox hide-detials label="Purchase Orders Required" dense class="mt-1" v-model="purchase_orders_required" @change="updatePurchaseOrdersReq" />
          </v-col>

          <v-col cols=12 class='py-0'><v-divider /></v-col>

          <v-col cols="12" class="pt-0">
            <AutoBilling :customer-id="customerId" />
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>

  </div>
</template>

<style lang="scss" scoped> //@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'
import LazyDropdown from '@/components/LazyDropdown'
import AutoBilling from './AutoBilling'
export default {
  name: 'Billing',
  components: { LazyDropdown, AutoBilling },
  props: {
    customerId: { type: String, required: true },
  },

  data: () => ({
    loading: false,
    abn: null,
    invoice_contact: null,
    invoice_address: null,
    por: null,
    tax: null,
    discount: null,
    invoice_comments: null,
    purchase_orders_required: null,
  }),

  computed: {
    taxId(){ return this.tax ? this.tax.xid : null; },
  },

  methods:{
    ...mapActions('UI', ['NOTIFY', 'PROMPT']),
    ...mapActions('API', ['API']),

    // called by parent when locations or staff are altered
    refreshLocations(){ this.$refs['address'].refresh(); },
    refreshStaff(){ this.$refs['contact'].refresh(); },

    async refreshBilling(){
      this.loading = true;
      let data = await this.API({ method: 'GET', endpoint: `customers/${this.customerId}/billing` });
      this.invoice_contact = data.billing_contact;
      this.invoice_address = data.billing_address;
      this.abn = data.ABN;
      this.por = data.purchase_orders_required;
      this.tax = data.tax;
      this.discount = data.discount;
      this.discountIsPercent = data.discountIsPercent;
      this.invoice_comments = data.invoice_comments;
      this.purchase_orders_required = data.purchase_orders_required;
      this.loading = false;
    },

    updateBilling(data){
      this.API({ method: 'PATCH', endpoint: `customers/${this.customerId}/billing`, data })
    },

    updateABN(ABN){ this.updateBilling({ ABN }); },
    updatePOR(purchase_orders_required){ this.updateBilling({ purchase_orders_required }); },
    updateDiscount(discount){ this.updateBilling({ discount }); },
    updateTax(tax){ this.updateBilling({ tax: tax?.xid ?? null }); },
    updateContact(contact){ this.updateBilling({ contact: contact?.xid ?? null }); },
    updateAddress(address){ this.updateBilling({ address: address?.xid ?? null }); },
    updateInvoiceComments(invoice_comments){ this.updateBilling({ invoice_comments }); },
    updatePurchaseOrdersReq(purchase_orders_required){ this.updateBilling({ purchase_orders_required }); },

  },

  mounted() {
    this.refreshBilling();
  },

}
</script>
