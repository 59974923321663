var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-list', {
    staticClass: "pt-0",
    attrs: {
      "dense": ""
    }
  }, [_c('v-subheader', [_vm._v("Base Charges"), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "small": "",
      "color": "primary"
    },
    on: {
      "click": _vm.clickAddItem
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('span', {
    ref: "sortArea"
  }, _vm._l(_vm.items, function (item) {
    return _c('v-list-item', {
      key: item.xid,
      staticClass: "px-1",
      attrs: {
        "data-id": item.xid
      },
      on: {
        "click": function click($event) {
          return _vm.clickEdit(item);
        }
      }
    }, [_c('v-list-item-icon', {
      staticClass: "dragMe"
    }, [_c('v-icon', [_vm._v("mdi-drag")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.qty) + "x "), item.product ? _c('span', [_vm._v(_vm._s(item.product.title))]) : _vm._e()]), item.product ? [_c('v-list-item-subtitle', [_vm._v(_vm._s(item.product.description))]), item.product.tier ? _c('v-list-item-subtitle', [_vm._v("$" + _vm._s((item.product.tier.price * item.qty / 100).toFixed(2)))]) : _vm._e()] : _vm._e()], 2), _c('v-list-item-action', {
      staticClass: "my-0"
    }, [_c('div', [item.eva ? _c('v-chip', {
      staticClass: "mr-1",
      attrs: {
        "x-small": "",
        "color": "primary"
      }
    }, [_vm._v("EVA")]) : _vm._e(), item.printed ? _c('v-chip', {
      staticClass: "mr-1",
      attrs: {
        "x-small": "",
        "color": "primary"
      }
    }, [_vm._v("3DP")]) : _vm._e(), item.vac ? _c('v-chip', {
      staticClass: "mr-1",
      attrs: {
        "x-small": "",
        "color": "primary"
      }
    }, [_vm._v("VAC")]) : _vm._e(), _c('v-btn', {
      attrs: {
        "small": "",
        "icon": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "red"
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.clickDelete(item);
        }
      }
    }, [_vm._v("mdi-trash-can")])], 1)], 1)])], 1);
  }), 1)], 1), _c('v-dialog', {
    attrs: {
      "value": _vm.dialog.open,
      "persistent": "",
      "scrollable": "",
      "max-width": "500px"
    }
  }, [_c('v-card', {
    attrs: {
      "disabled": _vm.dialog.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v("Base Charge")]), _c('v-card-text', {
    staticClass: "pt-6"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Qty"
    },
    model: {
      value: _vm.dialog.qty,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "qty", $$v);
      },
      expression: "dialog.qty"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('LazyDropdown', {
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "Product",
      "url": "products",
      "item-text": "title",
      "item-value": "xid"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('TierIcon', {
          staticClass: "mr-2",
          attrs: {
            "tier": item.tier,
            "size": "24px",
            "tooltip": true
          }
        }), _c('span', [_vm._v(_vm._s(item.title))])];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('TierIcon', {
          staticClass: "mr-4",
          attrs: {
            "tier": item.tier,
            "size": "30px",
            "tooltip": true
          }
        }), _c('span', [_vm._v(_vm._s(item.title))])];
      }
    }]),
    model: {
      value: _vm.dialog.product,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "product", $$v);
      },
      expression: "dialog.product"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-1 text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-chip', {
    staticClass: "ma-1",
    attrs: {
      "label": "",
      "small": "",
      "color": _vm.dialog.eva ? 'primary' : 'secondary'
    },
    on: {
      "click": function click($event) {
        _vm.dialog.eva = !_vm.dialog.eva;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-" + _vm._s(_vm.dialog.eva ? 'check' : 'close'))]), _vm._v("Machined EVA")], 1), _c('v-chip', {
    staticClass: "ma-1",
    attrs: {
      "label": "",
      "small": "",
      "color": _vm.dialog.printed ? 'primary' : 'secondary'
    },
    on: {
      "click": function click($event) {
        _vm.dialog.printed = !_vm.dialog.printed;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-" + _vm._s(_vm.dialog.printed ? 'check' : 'close'))]), _vm._v("3D Printed")], 1), _c('v-chip', {
    staticClass: "ma-1",
    attrs: {
      "label": "",
      "small": "",
      "color": _vm.dialog.vac ? 'primary' : 'secondary'
    },
    on: {
      "click": function click($event) {
        _vm.dialog.vac = !_vm.dialog.vac;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-" + _vm._s(_vm.dialog.vac ? 'check' : 'close'))]), _vm._v("Vacuum Formed")], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "grey lighten-4"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "secondary",
    on: {
      "click": function click($event) {
        _vm.dialog.open = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm.dialog.xid ? _c('v-btn', {
    staticClass: "primary",
    attrs: {
      "loading": _vm.dialog.loading
    },
    on: {
      "click": _vm.clickUpdate
    }
  }, [_vm._v("Update")]) : _c('v-btn', {
    staticClass: "primary",
    attrs: {
      "loading": _vm.dialog.loading
    },
    on: {
      "click": _vm.clickSave
    }
  }, [_vm._v("Save")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }