var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.LOGGED_IN ? _c('v-container', {
    staticClass: "mb-10",
    attrs: {
      "id": "page"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "10"
    }
  }, [_c('Education', [_c('ul', [_c('li', [_vm._v("Staff members can access and track jobs they are tagged on.")]), _c('li', [_vm._v("Customer Locations should be added which will be billed and shipped to.")]), _c('li', [_vm._v("Administrative staff can additionally access, track and pay for all jobs booked at their assigned locations.")])])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "7"
    }
  }, [_c('Notes', {
    staticClass: "mb-5",
    attrs: {
      "customerId": _vm.customerId
    }
  }), _c('Jobs', {
    staticClass: "mb-5",
    attrs: {
      "customerId": _vm.customerId
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "5"
    }
  }, [_c('Locations', {
    ref: "locations",
    staticClass: "mb-5",
    attrs: {
      "customerId": _vm.customerId
    },
    on: {
      "update": _vm.locationsUpdated
    }
  }), _c('Staff', {
    attrs: {
      "customerId": _vm.customerId
    },
    on: {
      "staffChanged": _vm.StaffUpdated
    }
  }), _c('Billing', {
    ref: "billing",
    attrs: {
      "customerId": _vm.customerId
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }