var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-sheet', {
    staticClass: "white rounded",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "rounded": "",
      "elevation": "2"
    }
  }, [_c('v-sheet', {
    staticClass: "primary pa-3"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-h6 white--text"
  }, [_vm._v("Staff")]), _c('v-col', {
    staticClass: "text-right pa-2"
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "fab": "",
      "small": "",
      "text": ""
    },
    on: {
      "click": _vm.clickNewStaff
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)], 1)], 1), _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.staff, function (s) {
    return _c('v-list-item', {
      key: s.xid
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(s.user.name_first) + " " + _vm._s(s.user.name_last) + " "), _c('span', {
      staticClass: "font-weight-regular"
    }, [_vm._v(" - " + _vm._s(s.user.username))])]), s.user.job_description ? _c('v-list-item-subtitle', [_vm._v(_vm._s(s.user.job_description))]) : _vm._e()], 1), _c('v-list-item-action', {
      staticClass: "py-0 my-0"
    }, [_c('v-menu', {
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "icon": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', {
            attrs: {
              "color": "primary"
            }
          }, [_vm._v("mdi-dots-horizontal")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', [_c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.editStaff_open(s.xid);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-3 primary--text"
    }, [_vm._v("mdi-pencil")]), _vm._v("Update Details")], 1), _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.clickPasswordReset(s);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-3 primary--text"
    }, [_vm._v("mdi-lock-reset")]), _vm._v("Reset Password")], 1), _c('v-divider'), _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.deleteStaff(s);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-3 red--text"
    }, [_vm._v("mdi-trash-can")]), _vm._v("Delete")], 1)], 1)], 1)], 1)], 1);
  }), 1)], 1), _vm.editStaff.show ? _c('v-dialog', {
    attrs: {
      "max-width": "600",
      "persistent": "",
      "scrollable": ""
    },
    model: {
      value: _vm.editStaff.show,
      callback: function callback($$v) {
        _vm.$set(_vm.editStaff, "show", $$v);
      },
      expression: "editStaff.show"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "20",
      "disabled": _vm.editStaff.loading
    }
  }, [_c('v-card-title', {
    staticClass: "text-h6 primary white--text py-3"
  }, [_vm._v(" Update Staff ")]), _c('v-card-text', {
    staticClass: "pt-8 text-subtitle-2"
  }, [_c('v-row', {
    staticStyle: {}
  }, [_c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "LaserCAM Cloud Username"
    },
    model: {
      value: _vm.editStaff.username,
      callback: function callback($$v) {
        _vm.$set(_vm.editStaff, "username", $$v);
      },
      expression: "editStaff.username"
    }
  })], 1)], 1), _c('v-row', {
    staticStyle: {}
  }, [_c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "First Name"
    },
    model: {
      value: _vm.editStaff.name_first,
      callback: function callback($$v) {
        _vm.$set(_vm.editStaff, "name_first", $$v);
      },
      expression: "editStaff.name_first"
    }
  })], 1), _c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Last Name"
    },
    model: {
      value: _vm.editStaff.name_last,
      callback: function callback($$v) {
        _vm.$set(_vm.editStaff, "name_last", $$v);
      },
      expression: "editStaff.name_last"
    }
  })], 1), _c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Job Description"
    },
    model: {
      value: _vm.editStaff.job_description,
      callback: function callback($$v) {
        _vm.$set(_vm.editStaff, "job_description", $$v);
      },
      expression: "editStaff.job_description"
    }
  })], 1), _c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Phone"
    },
    model: {
      value: _vm.editStaff.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editStaff, "phone", $$v);
      },
      expression: "editStaff.phone"
    }
  })], 1), _c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Email"
    },
    model: {
      value: _vm.editStaff.email,
      callback: function callback($$v) {
        _vm.$set(_vm.editStaff, "email", $$v);
      },
      expression: "editStaff.email"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary darken-1"
    },
    on: {
      "click": function click($event) {
        _vm.editStaff.show = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    attrs: {
      "color": "primary darken-1"
    },
    on: {
      "click": _vm.editStaff_save
    }
  }, [_vm._v("Update")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }